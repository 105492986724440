export default {
  title: "Trucks'n'Tricks",
  cta: "Download slides",
  description:
    "As game master, you can download data slides, remove players from the game and reset the game.",
  gameStarted: "This game has already started!",
  addPlayersInfo: "If you want new players to join, you must reset the game.",
  room: "Room",
  resetGame: "Reset game",
  players: "Players",
  removePlayer: "Remove",
  error: "This game id doesn't exist!",
  ctaError: "Back to login",
  gameCode: "Game Code",
  resetCode: "Refresh Code",
  allowStart: "Allow Start",
  saveResultsInfo: "Save Results in Adaptfy Academy",
  saveResultsAction: "Save"
};
