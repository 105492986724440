import React from "react";
import GamemasterRoute from "@routes/gamemaster/Gamemaster";
import { Router } from "@reach/router";

export const GameMaster = () => {
  return (
    <Router>
      <GamemasterRoute path="/gamemaster/:id" />
    </Router>
  );
};
export default GameMaster;
